export const projectsTranslation = {
    en : {
        DrivingRecords: {
            title: "Driving Records",
            description: "You are a person, who enjoys to drive and wants to have an idea how many kilometers You have drove? This mobile application allows you to store every driving You have done - distance, date, car. The app also offers live tracking/recording.",
            development: "Idea: Interactive app to make the life easier for the passionate people, who want to keep track on their drive through the time",
            developedWith: "Developed with: Kotlin, Firebase",
            completionDate: "Completed on: 8 Jan 2025",
            videoText: "Watch showcase of the app here"
        },
        AI_BanknoteRecognisionApp: {
            title: "AI Banknotes Recognition",
            description: "Mobile application designed for EUR and BGN banknotes recognitions. Returns image with recognized features on it, text and speech with the recognition result.",
            development: "Development: 1. Fine-tune YOLOv8m model, 2. Create a DEMO GUI App with Tkinter toolkit, 3. Develop Mobile Application as final service using Expo and FastAPI",
            developedWith: "Developed with: Python, TypeScript",
            completionDate: "Completed on: 28 Sep 2024"
        },
        AI_KPoPIdolsRecognition: {
            title: "AI KPoP Idols Recognition",
            description: "A system to recognize face from an image and make a classification in the area of  the KPoP Idols.",
            development: "Development: Fine-tune the ArcFace model",
            developedWith: "Developed with: Python",
            completionDate: "Completed on: 23 Sep 2024",
            videoText: "Watch demo here"
        },
        AI_TicTacToePlayer: {
            title: "AI TicTacToe Player",
            description: "Don't have a person to play with you? Or you want to challenge yourself? Then, the AI TicTacToe Player is the perfect opponent for you.",
            development: "Development: Uses the Minimax algorithm.",
            developedWith: "Developed with: Python",
            completionDate: "Completed on: 1 Aug 2024",
            videoText: "Watch the full gameplay here"
        },
        BeatTheMath: {
            title: 'Beat the Math', 
            description: "Want to combine fun with a math? Test how fast you can calculate and even improve such skills with this game. Player's objective is to pass as many gates as he can by choosing the better gate to move through. Each door has a math function, which will REDUCE or INCREASE player's X value.", 
            development: "This was my school degree project" , 
            developedWith: "Developed with: Unreal Engine",
            completitionDate: "Completed on: 11 Mar 2024",
            videoText: "Watch gameplay of the game here" 
        },
        SpaceShooterGame: {
            title: 'Space Shooter Game', 
            description: "The Player is the defender of our planet Earth and managing his ship in the Space he has to destroy the enemies' spaceships by shooting them, before they pass him. He also has to be careful to don't let a enemy spaceship to touch him, because this collision whil destroy his rocket. The Player has 3 lifes.",
            development: "Developed as course project of Telerik School Academy's Game Development course", 
            developedWith: 'Developed with: Phaser (HTML5 Game Framework)', 
            completitionDate: "Completed on: 30 Jun 2023",
            videoText:"Watch the full gameplay here"
        },
        KPoP_Quiz: {
            title: 'KPoP Quiz',
            description: 'You are a KPoP fan? Then this mobile game will test how good you know this music area offering different quiz categories - "Guess the Idol", "Guess the Group", "Guess the song" and others.', 
            development: 'Main Idea: Explore the main rival of Unreal Engine in the game development - Unity', 
            developedWith: 'Developed with: Unity', 
            completionDate: "Completed on: 31 Jul 2022",
            videoText: 'Watch gameplay of the game here'
        },
        EndlessRunner: {
            title: 'Endless Runner', 
            description: "Player has to jump over or crouch under the barriers, that are moving toward him. On collision with any, the player is coming close to the edge and will fall in the toxic liquid that is down there.", 
            development: "Personal Challenge: Create a game within 2 hours", 
            developedWith: 'Developed with: Unreal Engine, Blender', 
            completionDate: "Completed on: 13 Mar 2022",
            videoText: "Watch the full gameplay here"
        },
        InfiniteKorean: {
            title: 'Infinite Korean', 
            description: 'Want to learn a language on your own when you decide? "Infinite Korean" offers you a set of words in popular topics (numbers, animals, colors, etc.) giving you a basic skill to write, speak and understand simple sentences.' , 
            development: 'Aim: Recreate a popular mobile application (Infinite ... series). This is my first "big" and finished project.' , 
            developedWith: 'Developed with: C# and Xamarin' ,
            completionDate: "Completed on: 26 Jan, 2022",
            videoText: "Watch gameplay of the game here"
        }
    },
    bg: {
        DrivingRecords: {
            title: "Шофьорски записи",
            description: "Вие сте човек, който обича да шофира и искате да имате представа колко километра сте изминали? Това мобилно приложение ви позволява да съхранявате всяко шофиране, което сте направили - разстояние, дата, кола. Приложението предлага и проследяване/запис на живо.",
            development: "Идея: Интерактивно приложение, което да улесни живота на страстните хора, които искат да проследят пътя си през времето",
            developedWith: "Разработен с: Kotlin, Firebase",
            completionDate: "Завършен на: 8 януари 2025",
            videoText: "Гледайте демонстрация на приложението тук"
        },
        AI_BanknoteRecognisionApp: {
            title: "Разпознаване на банкноти с изкуствен интелект",
            description: "Мобилно приложение за разпознаване на банкноти в евро и левове. Връща изображение с разпознати елементи върху него, текст и реч с резултата от разпознаването.",
            development: "Разработка: 1. Фина настройка на модела YOLOv8m, 2. Използвах Tkinter, за да създам ДЕМО приложение, 3. Разработих мобилно приложение като крайна услуга чрез Expo и FastAPI",
            developedWith: "Разработен с: Python, TypeScript",
            completionDate: "Завършен на: 28 септември 2024г."
        },
        AI_KPoPIdolsRecognition: {
            title: "Разпознаване на KPoP идоли с изкуствен интелект",
            description: "Система за разпознаване на лице от изображение и извършване на класификация в областта на KPoP идолите.",
            development: "Разработка: Фина настройка на модела ArcFace",
            developedWith: "Разработен с: Python",
            completionDate: "Завършен на: 23 септември 2024",
            videoText: "Гледайте демонстрация тук"
        },
        AI_TicTacToePlayer: {
            title: "Изкуствен интелект играещ морски шах",
            description: "Нямате човек, който да играе с вас морски шах? Или искате да предизвикате себе си? Тогава този играч с изкуствен интелект е идеалният противник за вас.",
            development: "Разработка: Използва се алгоритъма на Минимакс",
            developedWith: "Разработен с: Python",
            completionDate: "Завършен на: 1 август 2024",
            videoText: "Гледайте пълното видео тук"
        },
        BeatTheMath: {
            title: "Победи Математиката",
            description: "Искате да комбинирате забавлението с математиката? Тествайте колко бързо можете да пресмятате и дори да подобрите това умение с тази игра. Целта на играча е да премине колкото се може повече порти, като избере по-добрата врата, през която да премине. Всяка врата има математическа формула, която ще НАМАЛИ или УВЕЛИЧИ X стойността на играча.",
            development: "Това е моят училищен дипломен проект",
            developedWith: "Разработен с: Unreal Engine",
            completionDate: "Завършен на: 11 март 2024",
            videoText: "Гледайте видео от играта тук"
        },
        SpaceShooterGame: {
            title: "Игра Космически стрелец",
            description: "Играчът е защитник на нашата планета Земя и управлявайки своя кораб в Космоса, той трябва да унищожи космическите кораби на враговете, като ги застреля, преди те да го подминат. Той също така трябва да внимава да не позволи на вражески космически кораб да го докосне, защото този сблъсък ще унищожи ракетата му. Играчът има 3 живота.",
            development: "Разработен като курсов проект за курса Разработка на игри на училищната академия Телерик",
            developedWith: "Разработен с: Phaser (HTML5 библиотека за игри)",
            completionDate: "Завършен на: 30 януари 2023",
            videoText: "Гледайте цялото видео на играта тук"
        },
        KPoP_Quiz: {
            title: "Мобилна игра КПОП викторина",
            description: "Вие сте KPoP фен? След това тази мобилна игра ще провери колко добре познавате тази музикална област, като предлага различни категории викторини - „Познай идола“, „Познай групата“, „Познай песента“ и други.",
            development: "Основна идея: Изследвайте основния съперник на Unreal Engine в разработката на играта - Unity",
            developedWith: "Разработен с: Unity",
            completionDate: "Завършен на: 31 юли 2022",
            videoText: "Гледайте видео от играта тук"
        },
        EndlessRunner: {
            title: 'Безкраен бегач', 
            description: "Играчът трябва да прескочи или да приклекне под бариерите, които се движат към него. При сблъсък с която и да е, играчът се доближава до ръба и ще падне в токсичната течност, която е там долу.", 
            development: "Лично пердизвикателство: Да създам игра за 2 часа", 
            developedWith: 'Разработен с: Unreal Engine, Blender', 
            completionDate: "Завършен на: 13 март 2022",
            videoText: "Гледайте пълното видео тук"
        },
        InfiniteKorean: {
            title: 'Безкраен Корейски', 
            description: 'Искате ли да научите език сами, когато решите? „Infinite Korean“ ви предлага набор от думи в популярни теми (числа, животни, цветове и т.н.), като ви дава основно умение да пишете, говорите и разбирате прости изречения.' , 
            development: 'Цел: Да пресъздам популярно мобилно приложение (серия Infinite ...). Това е първият ми "голям" и готов проект.' , 
            developedWith: 'Разработен с: C# и Xamarin' ,
            completionDate: "Завършен на: 26 януари 2022",
            videoText: "Гледайте видео от играта тук"
        }
    }
}