import React from "react";
import "../styles/PrivacyPolicy.css"; // Make sure to create a corresponding CSS file for styling

const PrivacyPolicy = () => {
  return (
    <div className="privacy-container">
      <div className="privacy-content">
        <h1 className="privacy-title">Privacy Policy</h1>
        <p className="privacy-date">Effective Date: 26.03.2025</p>

        <p>Welcome to <strong>Rokyuto</strong>. This Privacy Policy explains how I collect, use, and safeguard Your information when You visit my website (<a href="https://rokyuto.com">https://rokyuto.com</a>) and use my products. By accessing my website and using my services, You agree to the terms outlined in this policy.</p>

        <h2>1. Information Collected From You</h2>
        <h3>1.1 Personal Information:</h3>
        <ul>
          <li>When You use the contact form on my website, Your name, email address, and any other information You provide in Your message is collected.</li>
        </ul>

        <h3>1.2 Automatically Collected Information:</h3>
        <ul>
          <li>Your IP address, browser type, operating system, referring URLs, and browsing behavior through cookies and analytics tools.</li>
        </ul>

        <h2>2. Information Collected From You Via My Products</h2>
        <h3>2.1 For DRIVING RECORDS application:</h3>
        <h4>2.1.1 Account Information:</h4>
        <ul>
          <li>If You create an account, the name, email, and profile details You specify will be collected.</li>
        </ul>

        <h4>2.1.2 Driving Data:</h4>
        <ul>
          <li>Vehicle details (brand, model, registration number)</li>
          <li>Past driving history</li>
          <li>Real-time driving data (e.g. speed, location, route information)</li>
        </ul>

        <h4>2.1.3 Location Information:</h4>
        <ul>
          <li>If You enable location tracking, the app collects real-time GPS data to provide driving insights and route tracking.</li>
        </ul>

        <h2>3. How Your Information is Used</h2>
        <ul>
          <li>To operate, maintain, and improve my website and products.</li>
          <li>To respond to inquiries and provide customer support.</li>
          <li>To enhance user experience and develop new features.</li>
          <li>To analyze user interactions and improve performance.</li>
          <li>To comply with legal obligations and enforce our terms.</li>
        </ul>

        <h2>4. Sharing of Information</h2>
        <ul>
          <li>I do not sell or rent Your personal data.</li>
          <li>Information may be shared with third-party vendors that assist in operating my website or services.</li>
          <li>If required by law, Your data may be disclosed to legal authorities.</li>
        </ul>

        <h2>5. Data Security</h2>
          <ul>
            <li>I implement industry-standard security measures to protect Your personal data. However, no method of transmission over the internet is 100% secure. You are responsible for maintaining the confidentiality of Your account credentials.</li>
          </ul>

        <h2>7. Third-Party Links</h2>
        <ul>
          <li>My website and products may contain links to third-party websites. I am not responsible for their privacy practices and encourage You to review their privacy policies.</li>
        </ul>

        <h2>8. Changes to This Privacy Policy</h2>
        <ul>
          <li>There may be updates to this Privacy Policy from time to time. Changes will be posted on this page, and I will update the effective date accordingly.</li>
        </ul>

        <h2>9. Contact Information</h2>
        <p><strong>Viktor Asenov</strong></p>
        <p>Email: <a href="mailto:rokyuto@gmail.com">rokyuto@gmail.com</a></p>
        <p>Website: <a href="https://rokyuto.com">https://rokyuto.com</a></p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
